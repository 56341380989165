import { effect } from '@preact/signals-react';
import { io } from 'socket.io-client';
import { getConfig } from 'config/config';
import { $syncing, $toastr } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import { $authToken } from 'signals/Authentication.signals';
import Signal from 'signals/Signal';
import { handleNotification } from 'components/global/Alert/Alert';
import { ACCT_PROVIDER } from '@accru/client';

const completedSyncStatuses = ['SUCCESS', 'ERROR'];

const acctProviderNameMap = {
  [ACCT_PROVIDER.QUICKBOOKS]: 'QuickBooks',
};

const onOrganizationSync = (data) => {
  let syncData = [...$syncing.value.syncData || []];
  syncData.push(data);

  $syncing.update({ isSyncing: true, syncData });

  let allToasts = Array.from($toastr.value.toasts);
  if (!allToasts.find(t => t.type === 'sync' && t.id === data.organization_acct_provider_synchronization_id)) {
    allToasts = allToasts.filter(t => t.type !== 'sync');
    allToasts.push({
      id: data.organization_acct_provider_synchronization_id,
      expanded: true,
      type: 'sync',
    });
    $toastr.update({
      toasts: allToasts,
    });
  }

  if (data.type === 'BEGIN') $syncing.update({ isSyncing: true, isFinished: false, isSyncToastOpen: false });

  if (data.type === 'SYNCHRONIZATION' && completedSyncStatuses.includes(data.status)) {
    handleNotification(`Synchronized successfully with ${acctProviderNameMap[data.acct_provider]}!`, { variant: 'success' });
    syncData = syncData.filter(sync => sync.organization_acct_provider_synchronization_id !== data.organization_acct_provider_synchronization_id);
    $syncing.update({
      isSyncing: false,
      isFinished: true,
      syncData,
    });
  }

  const providersInSync = syncData.reduce((acc, sync) => {
    if (completedSyncStatuses.includes(sync.status)) return acc;
    if (!acc.includes(sync.acct_provider)) acc.push(sync.acct_provider);
    return acc;
  }, []);

  $syncing.update({ providersInSync });
};

const socket = io(`${getConfig('BACKEND_GRAPHQL_ENDPOINT').replace('/graphql', '')}`, {
  auth: { token: `Bearer ${$authToken.value}` },
  path: '/websocket',
  reconnectionDelay: 10000,
});

const updateSocketTokenAndReconnect = () => {
  socket.auth.token = `Bearer ${$authToken.value}`;
  socket.connect();
};

const disconnectSocket = () => {
  socket.disconnect();
};

const $websocket = Signal({
  client: socket,
  isConnected: false,
  isSyncToastOpen: null,
  syncData: [],
});

socket.on('connect', () => $websocket.update({ isConnected: true }));
socket.on('disconnect', (reason) => {
  $websocket.update({ isConnected: false });
  if (reason === 'io server disconnect') updateSocketTokenAndReconnect();
});
socket.on('ping', () => socket.emit('pong'));

socket.on('organizationSynchronization', onOrganizationSync);

effect(() => {
  if ($user?.value?.currentOrganization?.id !== $user?.previousValue?.currentOrganization?.id ||
    $authToken.value !== $authToken.previousValue
  ) {
    if ($user?.value?.currentOrganization?.id && $authToken.value) updateSocketTokenAndReconnect();
    else disconnectSocket();
  }
}, [$user, $authToken]);
