/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import { STATEMENT_LINE_TYPE } from '@accru/client';
import { accruClient } from 'api';

export async function fetchStatementData({
  unconnectedRequestData,
  params,
  type,
  viewerMode,
  targetId,
  organizationId,
}) {
  const loggedInParams = {
    organizationId,
    organizationCustomerId: targetId,
    params,
  };

  const unconnectedParams = {
    email: unconnectedRequestData.email,
    token: unconnectedRequestData.token,
    uniqueCode: targetId,
    params,
  };

  const functionMap = {
    invoice: {
      VENDOR: () => fetchAndFormatVendorInvoiceSummary(loggedInParams),
      CUSTOMER: () => fetchAndFormatCustomerInvoiceSummary(loggedInParams),
      UNCONNECTED_CUSTOMER: () => fetchAndFormatUnconnectedCustomerInvoiceSummary(unconnectedParams),
    },
    history: {
      VENDOR: () => fetchAndFormatVendorHistorySummary(loggedInParams),
      CUSTOMER: () => fetchAndFormatCustomerHistorySummary(loggedInParams),
      UNCONNECTED_CUSTOMER: () => fetchAndFormatUnconnectedHistorySummary(unconnectedParams),
    },
  };

  const data = await functionMap[type][viewerMode]();
  return data;
}

export async function fetchAndFormatVendorInvoiceSummary({
  organizationId,
  organizationCustomerId,
  params,
}) {
  const data = await accruClient.invoices.get({
    organizationId,
    organizationCustomerId,
    ...params,
  });
  return formatResponse({ data, type: 'invoice' });
}

export async function fetchAndFormatCustomerInvoiceSummary({
  organizationId,
  organizationCustomerId,
  params,
}) {
  const data = await accruClient.statements.getInvoiceSummary({
    organizationId,
    organizationCustomerId,
    ...params,
  });

  return formatResponse({ data, type: 'invoice' });
}

export async function fetchAndFormatUnconnectedCustomerInvoiceSummary({
  email,
  token,
  uniqueCode,

  params,
}) {
  const data = await accruClient.invoices.getUnconnectedSummary({
    email,
    token,
    uniqueCode,

    ...params,
  });

  return formatResponse({ data, type: 'invoice' });
}

export async function fetchAndFormatVendorHistorySummary({
  organizationId,
  organizationCustomerId,
  params,
}) {
  const data = await accruClient.customers.getStatement({
    organizationId,
    organizationCustomerId,
    ...params,
  });

  return formatResponse({ data, type: 'history' });
}

export async function fetchAndFormatCustomerHistorySummary({
  organizationId,
  organizationCustomerId,
  params,
}) {
  const data = await accruClient.customers.getStatement({
    organizationId,
    organizationCustomerId,
    ...params,
  });

  return formatResponse({ data, type: 'history' });
}

export async function fetchAndFormatUnconnectedHistorySummary({
  email,
  token,
  uniqueCode,

  params,
}) {
  const data = await accruClient.customers.getStatement({
    email,
    token,
    uniqueCode,

    ...params,
  });

  return formatResponse({ data, type: 'history' });
}

function formatResponse({ data, type }) {
  return {
    totalBalance: data.latest_acct_provider_balance,
    lastUpdated: data.last_sync_at,
    customer: {
      name: data.vendor_organization_customer.name,
    },
    vendor: {
      name: data.vendor_organization.name,
      address1: data.vendor_organization.address_line_1,
      address2: data.vendor_organization.address_line_2,
      city: data.vendor_organization.address_city,
      state: data.vendor_organization.address_state,
      zip: data.vendor_organization.address_zip_code,
      email: data.vendor_organization.email,
      phone: data.vendor_organization.phone_number,
      logo: data.vendor_organization.logo_picture_file?.public_url,
    },
    invoiceCounts: {
      open: data.total_open_invoice_count,
      overdue: data.total_overdue_invoice_count,
    },
    aging: {
      '1-30': data.latest_acct_provider_one_to_thirty_days_due_amount,
      '31-60': data.latest_acct_provider_thirty_one_to_sixty_days_due_amount,
      '60 +': data.latest_acct_provider_sixty_plus_days_due_amount,
    },
    items: type === 'invoice'
      ? data.items
      : data.data.edges.map((item) => formatHistoryItem(item)),
  };
}

function formatHistoryItem(item) {
  if (item.node.type === STATEMENT_LINE_TYPE.INVOICE) {
    return {
      ...item.node,
      ...item.node.organization_invoice,
    };
  }
  return {
    ...item.node,
    ...item.node.organization_invoice_transaction,
  };
}
