/* eslint-disable import/prefer-default-export */
import { STATEMENT_LINE_TYPE } from '@accru/client';
import Money from 'components/global/Money';
import { StatusBadge } from 'components/global/StatusPills/StatusPills';
import { handleClickViewPay } from 'components/views/Statement/statements.helpers';
import moment from 'moment';
import { Button } from 'react-bootstrap';

/* eslint-disable no-use-before-define */
export const statementTableFormat = ({ items, type }) => ({
  headers: type === 'invoice' ? getInvoiceTableHeaders() : getHistoryTableHeaders(),
  rows: type === 'invoice' ? getInvoiceTableRows(items) : getHistoryTableRows(items),
});

function getInvoiceTableRows(items) {
  const rows = items.map((item) => ({
    Date: () => (
      <>{moment(item.invoice_date).format('MMM D, YYYY')}</>
    ),
    DueDate: () => (
      <>{moment(item.due_date).format('MMM D, YYYY')}</>
    ),
    Balance: () => (
      <Money amount={item.latest_acct_provider_balance} />
    ),
    Total: () => (
      <Money amount={item.amount} />
    ),
    Status: () => {
      if (!item.last_sync_at) return <span />;
      if (item.latest_acct_provider_status === 'PAID') {
        return (<StatusBadge status="paid" className="border" />);
      }
      if (moment(item.due_date) < moment()) {
        return (<StatusBadge status="open" isOverdue />);
      }
      return (<StatusBadge status="open" className="border" />);
    },
    Action: () => {
      const Component = item.status === 'OPEN'
        ? <Button variant="link" className="ps-0" onClick={() => handleClickViewPay(item)}>View/Pay</Button>
        : <Button variant="link" className="ps-0" onClick={() => handleClickViewPay(item)}>View Details</Button>;
      return Component;
    },
    ...item,
  }));
  return rows;
}

function getHistoryTableRows(items) {
  const rows = items.map((item) => ({
    Reference: () => {
      const prefix = item.type === STATEMENT_LINE_TYPE.INVOICE ? 'Inv' : 'Ref';
      const key = item.type === STATEMENT_LINE_TYPE.INVOICE ? 'code' : 'code'; // Todo: This needs to be reference number
      return `${prefix}-${item[key]}`;
    },
    Date: () => (
      <>{moment(item.date).format('MMM D, YYYY')}</>
    ),
    DueDate: () => (
      <>{moment(item.due_date).format('MMM D, YYYY')}</>
    ),
    Balance: () => (
      <Money amount={item.running_balance} />
    ),
    Type: () => {
      const title = item.type === STATEMENT_LINE_TYPE.INVOICE ? 'Invoice' : 'Payment';
      const className = item.type === STATEMENT_LINE_TYPE.INVOICE ? 'text-dark' : 'text-success fw-700';
      return <span className={className}>{title}</span>;
    },
    Total: () => (
      <Money amount={item.amount} />
    ),
    ...item,
  }));
  return rows;
}

function getInvoiceTableHeaders() {
  return [
    {
      sortKey: 'number',
      key: 'number',
      value: 'Invoice No.',
    },
    {
      sortKey: 'invoice_date',
      key: 'Date',
      value: 'Invoice Date',
    },
    {
      sortKey: 'due_date',
      key: 'DueDate',
      value: 'Due Date',
    },
    {
      sortKey: 'total_amount',
      key: 'Total',
      value: 'Orig Amount',
    },
    {
      sortKey: 'latest_acct_provider_balance',
      key: 'Balance',
      value: 'Balance',
    },
    {
      sortKey: 'latest_acct_provider_status',
      key: 'Status',
      value: 'Status',
    },
    {
      key: 'Action',
      value: 'Action',
    },
  ];
}

function getHistoryTableHeaders() {
  return [
    {
      key: 'Reference',
      value: 'Reference',
    }, {
      key: 'Type',
      value: 'Type',
    },
    {
      sortKey: 'date',
      key: 'Date',
      value: 'Date',
    },
    {
      sortKey: 'due_date',
      key: 'DueDate',
      value: 'Due Date',
    },
    {
      sortKey: 'amount',
      key: 'Total',
      value: 'Amount',
    },
    {
      key: 'Balance',
      value: 'Running Balance',
    },
  ];
}
